const SearchIcon = () => {
  return (
    <svg
      className="h-5 w-5 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 2C8.134 2 5 5.134 5 9c0 6.1 7 13 7 13s7-6.9 7-13c0-3.866-3.134-7-7-7zm0 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </svg>
  );
};
export default SearchIcon;
