import SearchIcon from "@/icons/search";
import { FC } from "react";
import { useTranslations } from "next-intl";
interface SearchFormProps {
  searchTerm: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const SearchForm: FC<SearchFormProps> = ({
  searchTerm,
  handleSearchChange,
}) => {
  const t = useTranslations();
  return (
    <form className="mx-auto max-w-md">
      <label
        htmlFor="default-search"
        className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
          <SearchIcon />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 ps-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder={t("main_search_placeholder")}
          value={searchTerm}
          onChange={handleSearchChange}
          required
        />
      </div>
    </form>
  );
};
