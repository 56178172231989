"use client";

import { useFormState } from "react-dom";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import Form from "@/components/Contact/form";
import { help } from "@/app/[locale]/help/help";
import { useTranslations } from "next-intl";

const Contact = () => {
  const [state, action] = useFormState(help, {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    deviceModel: "",
    iccid: "",
    message: "",
  });

  const t = useTranslations();
  useEffect(() => {
    if (state && state.error) {
      toast.error(state.error);
    } else if (state && state.success) {
      toast.success(t('ticket_submitted'),{
        position:"bottom-center",
        duration: 3000,
      })
    }
  }, [state]);

  return (
    <section
      id="contact"
      className="overflow-hidden border-y border-[#ddd] bg-gray-50"
    >
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div
              className="shadow-three mb-12 rounded-xl  py-11 "
              data-wow-delay=".15s"
            >
              <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-3xl lg:text-2xl xl:text-3xl">
                {t("need_ticket")}
              </h2>
              <p className="text-body-color mb-12 text-base font-medium">
                {t("our_support_will_back")}
              </p>
              <form action={action}>
                <Form state={state} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
