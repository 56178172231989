import { z } from "zod";

export const HelpFormSchema = z.object({
  firstName: z
    .string()
    .min(2, { message: "Name must be at least 2 characters long." })
    .trim(),
  lastName: z.string().trim(),
  email: z.string().email({ message: "Please enter a valid email." }).trim(),
  mobileNumber: z.string().trim(),
  // deviceModel: z.string().trim(),
  // iccid: z.string().trim(),
  message: z
    .string()
    .min(5, {
      message: "Message must be at least 5 characters long.",
    })
    .trim(),
});

export type FormState =
  | { error: string }
  | {
      errors?: {
        firstName?: string[];
        lastName?: string[];
        email?: string[];
        mobileNumber?: string[];
        deviceModel?: string[];
        iccid?: string[];
        message?: string[];
      };
      message?: string;
    }
  | undefined | {success: string};
