import Link from "next/link";
import Image from "next/image";
import ArrowIcon from "@/icons/arrowIcon";
import { FC } from "react";
import { ICountry } from "@/types/country";
import { useLocale, useTranslations } from "next-intl";
import countries from "@/components/Countries/index";
import { useRouter } from "next/navigation";
import { locales } from "@/config";
interface CountryProps {
  country: ICountry;
}
const Country: FC<CountryProps> = ({ country }) => {
  const locale = useLocale();
  const route = useRouter();
  const countriesList = useTranslations("countries_list");

  const handleClick = () => {
    if (locales.includes(country.code.toLowerCase())) {
      route.push(`/${locale}/${country.code}-packages`);
    } else {
      route.push(`${country.code}-packages`);
    }
  };
  return (
    <div key={country.id} className="w-full">
      <div
        // href={country.code}
        onClick={handleClick}
        className=" word-break-word relative flex h-full transform-gpu cursor-pointer items-center justify-center gap-4 rounded-lg border-none bg-gray-100 p-6  transition-colors hover:bg-gray-200 rtl:text-right"
      >
        <div className="flex h-full w-full items-center gap-4">
          <Image
            src={country.image}
            width={100}
            height={100}
            className="border-lg border-neutral-0 relative h-9 w-9 min-w-9 shrink-0 overflow-hidden rounded-full object-cover"
            alt={`Flag of ${country.name}`}
          />
          <p className="body-md-medium md:body-lg-medium ">
            {countriesList(`${country.code}`)}
          </p>
          <div className=" ml-auto -rotate-90">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Country;
