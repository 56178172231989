import Loading from "@/components/Loading/loading";
import { useTranslations } from "next-intl";

export const ToggleShowAllButton = ({
  buttonLoading,
  showAll,
  toggleShowAll,
}) => {
  const t = useTranslations();

  return (
    <div className="mt-4 text-center">
      <button
        onClick={toggleShowAll}
        className="ease-in-up shadow-btn hover:shadow-btn-hover mx-auto mt-10 rounded-2xl bg-primary px-8 py-3 text-base font-medium text-white transition duration-300 hover:bg-opacity-90 md:block md:px-9 lg:px-6 xl:px-9"
      >
        {buttonLoading ? (
          <Loading />
        ) : showAll ? (
            t("show_popular")
        ) : (
          t("show_all")
        )}
      </button>
    </div>
  );
};
