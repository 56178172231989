import { useState, useEffect } from "react";
import { ICountry } from "@/types/country";
import { BASE_URL } from "@/constants";
import { IPackage, IPackageResponse } from "@/types/package";
import { FilterOptions } from "@/components/Countries";
import { useLocale } from "next-intl";

interface UseCountriesConfig {
  stop?: boolean;
  url: string;
}

const filterUrl = {
  local: "countries",
  regional: "countries/regions",
  global: "packages?code=WRLD",
};

const useCountries = ({ stop, url = "countries" }: UseCountriesConfig) => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [packages, setPackages] = useState<IPackage[] | null>(null);
  const locale = useLocale();

  useEffect(() => {
    if (stop) return;
    const fetchCountries = async () => {
      setLoading(true);
      try {
        const response: any = await fetch(
          BASE_URL + filterUrl[url] + (url === "global" ? "" : `/${locale}`),
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (url === FilterOptions.GLOBAL) {
          const packagesData = data as IPackageResponse;
          setPackages(packagesData.data);
        } else {
          const countriesData = data as ICountry[];
          setCountries(countriesData);
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, [url, stop]);
  return { countries, packages, loading, error };
};

export default useCountries;
