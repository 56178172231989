"use client";

import React, { useEffect, useRef, useState } from "react";
import useCountries from "@/lib/useCountry";
import Country from "@/components/Countries/Country";
import PlanCard from "@/components/Plan";
import { IPackage } from "@/types/package";
import { SearchForm } from "@/components/Countries/SearchForm";
import { FilterButtons } from "@/components/Countries/FilterButtons";
import { ToggleShowAllButton } from "@/components/Countries/ToggleShowAllButton";
import Popup from "@/app/[locale]/ui/country/Popup";
import Loading from "@/components/Loading/loading";
import { useTranslations } from "next-intl";

export enum FilterOptions {
  LOCAL = "local",
  REGIONAL = "regional",
  GLOBAL = "global",
}
const textForFilter = {
  local: "local_name",
  regional: "regions",
  global: "global_esims",
};
const Countries = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("local");
  const isLocal = selectedFilter === FilterOptions.LOCAL;
  const isLocalOrRegional =
    isLocal || selectedFilter === FilterOptions.REGIONAL;
  const isGlobal = selectedFilter === FilterOptions.GLOBAL;

  const {
    countries,
    packages,
    loading: initialLoading,
    error,
  } = useCountries({
    url: selectedFilter,
    // stop: selectedFilter === "global",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<IPackage>();
  const t = useTranslations();
  const [showAll, setShowAll] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [searchLoading, setSearchLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const toggleShowAll = () => {
    setButtonLoading(true);
    setTimeout(() => {
      setShowAll((prevShowAll) => {
        if (prevShowAll && containerRef.current) {
          // Scroll to the container when switching back to popular countries
          containerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        return !prevShowAll;
      });
      setButtonLoading(false);
    }, 300); // Simulating a delay for loading spinner
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    setSearchLoading(true);
    searchTimeoutRef.current = setTimeout(() => {
      const filtered = countries.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredCountries(filtered);
      setSearchLoading(false);
    }, 300); // Debounce delay
  };
  useEffect(() => {
    if (!searchTerm) {
      setFilteredCountries(countries);
    }
  }, [countries, searchTerm]);

  const visibleCountries =
    showAll && isLocal ? filteredCountries : filteredCountries?.slice(0, 18);

  if (initialLoading) return <Loading />;

  if (error) return <div>Error: {error}</div>;

  return (
    <div
      ref={containerRef}
      className="dark:bg-gray-dark container mx-auto rounded bg-white p-4"
    >
      {isLocal && (
        <SearchForm
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
        />
      )}

      <h2 className="my-8 text-center text-3xl font-bold">
        {t(textForFilter[selectedFilter])}
      </h2>

      <FilterButtons
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />

      {searchLoading || initialLoading ? (
        <Loading />
      ) : (
        <div className="w-full/3 grid gap-3 md:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          {/*ui for global packages */}
          {isGlobal && packages?.length
            ? packages.map((plan) => (
                <PlanCard
                  key={plan.id}
                  plan={plan}
                  setShowModal={setShowModal}
                  setSelectedPlan={setSelectedPlan}
                />
              ))
            : null}

          {/*ui for showing countries */}
          {isLocalOrRegional &&
            visibleCountries.map((country) => (
              <Country key={country.id} country={country} />
            ))}
        </div>
      )}
      {!searchTerm && selectedFilter === FilterOptions.LOCAL && (
        <ToggleShowAllButton
          buttonLoading={buttonLoading}
          showAll={showAll}
          toggleShowAll={toggleShowAll}
        />
      )}
      {showModal && selectedPlan && (
        <Popup
          plan={selectedPlan}
          showModal={showModal}
          setShowModal={setShowModal}
          image={selectedPlan.operatorImage}
        />
      )}
    </div>
  );
};

export default Countries;
