import { FC } from "react";
import { useTranslations } from "next-intl";

const filterOptions = [
  { label: "local_esims", value: "local" },
  { label: "regional_esims", value: "regional" },
  { label: "global_esims", value: "global" },
];

interface FilterButtonsProps {
  selectedFilter: string;
  setSelectedFilter: (value: string) => void;
}
export const FilterButtons: FC<FilterButtonsProps> = ({
  selectedFilter,
  setSelectedFilter,
}) => {
  const t = useTranslations();

  return (
    <div className="my-8 flex flex-wrap justify-center gap-3">
      {filterOptions.map((option) => (
        <button
          key={option.value}
          onClick={() => setSelectedFilter(option.value)}
          className={`${
            selectedFilter.includes(option.value)
              ? "bg-primary text-white"
              : "bg-white text-black"
          } ease-in-up border-primary-400  shadow-btn hover:shadow-btn-hover flex rounded-2xl border px-8 py-3 text-base font-medium text-black transition duration-300 hover:bg-opacity-90 md:px-9 lg:px-6 xl:px-9`}
        >
          {t(option.label)}
        </button>
      ))}
    </div>
  );
};
